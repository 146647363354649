<template>
  <!-- 字典类型 -->
  <div class="dictionaryTypePageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="searchFormItemArr" :reset="false" />
    <!--列表-->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="addAndEdit">
          添加
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :loading="loading" :operation-button="operationButton" />
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 编辑与新增界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="35%" :close-on-click-modal="false" @click="closeDialog('edit')">
      <el-form ref="editForm" label-width="80px" :model="editForm" :rules="rules">
        <el-form-item label="字典名称" prop="descrip">
          <el-input v-model="editForm.descrip" size="small" auto-complete="off" placeholder="请输入字典名称" />
        </el-form-item>
        <el-form-item label="字典类型" prop="dictType">
          <el-input v-model="editForm.dictType" size="small" auto-complete="off" placeholder="请输入字典类型" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain class="widen-button" @click="closeDialog('edit')">
          取消
        </el-button>
        <!-- <el-button size="small" @click="$resetForm('editForm')" type="success" plain>重置</el-button> -->
        <el-button size="small" type="primary" :loading="loading" class="title widen-button" @click="submitForm('editForm')">
          保存
        </el-button>
      </div>
    </el-dialog>
    <!-- 弹出明细框 -->
    <el-dialog title="明细表" :visible.sync="detailDialogBox" width="64%" :close-on-click-modal="false" @click="closeDialog('edit')">
      <DictionaryData :dict-type="dictType" />
    </el-dialog>
  </div>
</template>

<script>
import { getDictionaryTypePage, saveDictionaryType, deleteDictionaryType } from '../../api/systemManagement'
import Pagination from '../../components/Pagination2'
import Table from '@/components/Table'
import DictionaryData from './dictionaryData.vue'
import FormSearch from '../../components/FormSearch'
export default {
  components: { Pagination, Table, FormSearch, DictionaryData },
  data() {
    return {
      editFormVisible: false,
      detailDialogBox: false,
      dictType: '',
      rules: {
        dictType: [{ required: true, message: '请输入字典类型', trigger: 'blur' }],
        descrip: [{ required: true, message: '请输入字典名称', trigger: 'blur' }]
      },
      editForm: {},
      listData: [],
      title: '添加字典类型',
      total: 0,
      loading: false,
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      searchFormItemArr: [
        { type: 'input', label: '操作类型', value: 'dictType' },
        { type: 'input', label: '业务类型', value: 'descrip' }
      ],
      itemData: [
        { label: '字典类型', prop: 'dictType' },
        { label: '字典名称', prop: 'descrip' }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.addAndEdit },
        { bType: 'primary', label: '明细', handleEvent: this.showDetailDialog },
        { bType: 'danger', label: '删除', handleEvent: this.deleteRow }
      ]
    }
  },
  methods: {
    // 编辑按钮
    addAndEdit(row) {
      this.editFormVisible = true
      if (row.constructor === Object) {
        this.editForm = { ...row }
        this.title = '编辑字典类型'
        return
      }
      this.editForm = {}
      this.title = '添加字典类型'
    },
    closeDialog(formName) {
      // this.$refs[formName].resetFields()
      this.editFormVisible = false
    },
    showDetailDialog(row) {
      this.detailDialogBox = true
      this.dictType = row.dictType
    },
    // 获取数据
    getdata(type) {
      if (!type) this.formInline.pageNum = 1
      getDictionaryTypePage(this.formInline, res => {
        this.total = res.data.total
        this.listData = [...res.data.pageData]
      })
    },
    // 保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          this.loading = true
          // 请求方法
          saveDictionaryType(this.editForm, res => {
            this.loading = false
            this.editFormVisible = false
            this.getdata(true)
          }, () => {
            this.loading = false
          })
        }
      })
    },
    // 删除字典类型
    deleteRow(row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteDictionaryType(row.id, () => {
            this.$message.success('删除成功！')
            this.getdata(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.dictionaryTypePageBox {
  .el-dialog__body{
    padding-top: 0;
  }
  .search {
    margin-top: 20px;
  }
}
</style>
